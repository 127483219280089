/* eslint-disable max-lines */
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { PdLetDirectiveModule } from '@cdk/pd-let';

import { BASE_PATH as BPM_BASE_PATH } from '@data-access/bpm-generated';
import { DsBreadcrumbsModule } from '@design-system/components/breadcrumbs';
import { DsTextIndicatorModule } from '@design-system/components/text-indicator';
import { DS_APP_WRAPPER_CONFIG } from '@design-system/feature/app-wrapper-v2';
import { DsPageModule } from '@design-system/feature/page';
import { DsPrintModule } from '@design-system/feature/print';
import {
  DS_DISABLE_SPOTLIGHTS,
  DsSpotlightModule,
} from '@design-system/feature/spotlight';
import { AuthTokens, SharedFeatAuthModule } from '@features/auth';

import { RootEffects, RootState, rootReducer } from '@features/root-store';
import { SharedLibFeatTranslationModule } from '@features/translation';
import { EffectsModule } from '@ngrx/effects';
import {
  FullRouterStateSerializer,
  RouterStateSerializer,
  StoreRouterConnectingModule,
} from '@ngrx/router-store';
import { ActionReducerMap, StoreModule } from '@ngrx/store';
import {
  BASE_PATH as HELP_PAGES_BASE_PATH,
  HELP_PAGES_CONFIG,
  HelpPagesConfig,
} from '@paldesk/design-system/feature/help-page';
import { SharedLibFeaturesFreshdeskWidgetModule } from '@paldesk/shared-lib/features/freshdesk-widget';

import {
  AppWrapperModule,
  HEALTH_CHECK_OPTIONS_TOKEN,
  HealthCheckBannerOptions,
} from '@design-system/feature/app-wrapper-v2';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import {
  BASE_PATH as CONFIGURATION_BASE_PATH,
  SalesLibsConfigurationDataAccessModule,
} from '@sales-libs/configuration/data-access';
import {
  BASE_PATH as CUSTOM_ARTICLE_BASE_PATH,
  SalesLibsCustomArticleDataAccessModule,
} from '@sales-libs/custom-article/data-access';
import {
  BASE_PATH as ORDERS_BASE_PATH,
  SalesLibsOrderDataAccessModule,
} from '@sales-libs/order/data-access';
import {
  BASE_PATH as PRODUCT_BASE_PATH,
  SalesLibsProductDataAccessModule,
} from '@sales-libs/product/data-access';
import {
  BASE_PATH as PROJECT_BASE_PATH,
  SalesLibsProjectDataAccessModule,
} from '@sales-libs/project/data-access';
import { SlProjectModule } from '@sales-libs/project/feature';
import {
  BASE_PATH as SC_CONFIGURATION_BASE_PATH,
  SlScConfigurationDataAccessModule,
} from '@sales-libs/sc-configuration/data-access';
import { BASE_PATH as INQUIRY_BASE_PATH } from '@sales-libs/sc-inquiries/data-access';
import {
  BASE_PATH as QUESTIONNAIRE_BASE_PATH,
  SlScQuestionnaireDataAccessModule,
} from '@sales-libs/sc-questionnaire/data-access';
import { BASE_PATH as SC_SUMMARY_BASE_PATH } from '@sales-libs/sc-summary/data-access';
import {
  BASE_PATH as SETTING_BASE_PATH,
  SalesLibsSettingsDataAccessModule,
} from '@sales-libs/settings/data-access';
import {
  BASE_PATH as SC_SHARED_BASE_PATH,
  SlSharedScGeneratedDataAccessModule,
} from '@sales-libs/shared/data-access/sc-generated';
import {
  ScSharedEffects,
  ScSharedReducers,
  ScSharedStoreModule,
} from '@sales-libs/shared/feature';
import {
  CART_MAX_VALID_UNTIL_DAYS,
  FEATURE_FLAG_3D_VIEW,
  FEATURE_FLAG_BENEFITS,
  FEATURE_FLAG_BEST_INVEST,
  FEATURE_FLAG_BULK_ORDERS,
  FEATURE_FLAG_DIGITAL_OFFER,
  FEATURE_FLAG_EARLY_ACCESS,
  FEATURE_FLAG_MCC,
  FEATURE_FLAG_PALSHOW,
  FEATURE_FLAG_PPI,
  FEATURE_FLAG_PROJECT_SETTINGS,
  FEATURE_FLAG_REPORTING,
  FEATURE_FLAG_SERVICE_CONTRACTS,
  FEATURE_FLAG_SOLUTIONS_INTEGRATION,
  FEATURE_FLAG_WHOLESALE_CHECKBOX,
  PALDESK_BASE_BATH_TOKEN,
  PALFINGER_API_MANAGEMENT_SUBS_KEY,
  PALSHOW_DOMAIN,
  SCC_DOMAIN,
  SalestoolSharedModule,
  SlRouterContainerComponent,
} from '@sales-libs/shared/util';
import { BASE_PATH as UPSELLING_BASE_PATH } from '@sales-libs/upselling/data-access';
import { APPLICATION_INSIGHTS_KEY } from '@shared-lib/app-insights';
import { BASE_PATH as USER_SERVICE_BASE_PATH } from '@shared-lib/data-access/identity-service-generated';
import {
  GaService,
  GoogleAnalytics4MeasurementId,
  GoogleAnalytics4Module,
  GoogleAnalyticsTrackingId,
} from '@shared-lib/google-analytics';
import { CustomRouterStateSerializer } from '@shared-lib/rxjs';
import {
  API_MANAGEMENT_CONFIG_TOKEN,
  HttpErrorInterceptor,
  HttpInterceptorModule,
  HttpInterceptorTokens,
} from '@utils/interceptors';
import player from 'lottie-web';
import { provideLottieOptions } from 'ngx-lottie';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { routes } from './app.routing';

interface State {
  root: RootState;
  // router: RouterReducerState;
}

const coreReducers: ActionReducerMap<State, any> = {
  root: rootReducer,
  // router: routerReducer,
};

export function playerFactory() {
  return player;
}

function configuratorBasePathFactory() {
  return environment.palfinger_app_gateway + '/configurator';
}

function scBasePathFactory() {
  return environment.palfinger_app_gateway + '/solutions-configurator';
}

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule.forRoot(routes),
    SharedLibFeatTranslationModule,
    StoreModule.forRoot(coreReducers, {
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      },
    }),
    EffectsModule.forRoot([RootEffects]),
    StoreModule.forFeature('ScSharedState', ScSharedReducers.scShared),
    EffectsModule.forFeature([ScSharedEffects]),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
      connectInZone: true,
    }),
    StoreRouterConnectingModule.forRoot({
      serializer: FullRouterStateSerializer,
    }),
    HttpInterceptorModule.forRoot({
      provide: API_MANAGEMENT_CONFIG_TOKEN,
      useFactory: () => ({
        subscriptionKey: environment.palfinger_api_management_subs_key,
        whitelistedDomains: environment.domainsForApiManagementSubsKey,
      }),
    }),
    SharedFeatAuthModule.forRoot(),
    // Google Analytics
    GoogleAnalytics4Module.forRoot({
      provide: GoogleAnalytics4MeasurementId,
      useFactory: () => environment.google_analytics_id,
    }),

    PdLetDirectiveModule,
    DsBreadcrumbsModule,
    // Sales Libs
    SalestoolSharedModule,
    SalesLibsConfigurationDataAccessModule,
    SalesLibsCustomArticleDataAccessModule,
    SalesLibsProductDataAccessModule,
    SalesLibsProjectDataAccessModule,
    SalesLibsSettingsDataAccessModule,
    SalesLibsOrderDataAccessModule,
    DsPageModule,
    AppWrapperModule.forRoot([
      {
        provide: DS_APP_WRAPPER_CONFIG,
        useFactory: () => ({
          apiBasePath: environment.palfinger_api_management,
          productScreenBasePath: environment.LINKS.PRODUCT_SCREEN,
          isNativeApp: false,
          paldeskBasePath: environment.LINKS.PALDESK_BASEPATH,
          paldeskSupportPath: environment.LINKS.STATUS_PALDESK_PAGE,
          appInsightsRole: 'FE: Configure Price Quote',
          appGatewayBasePath: environment.palfinger_app_gateway,
          appName: 'Configure Price Quote',
          appHelpPath: environment.LINKS.APP_HELP_PATH,
          appNewsPath: environment.LINKS.APP_NEWS_PATH,
          useCSISurvey: environment.FEATUREFLAGS.USE_CSI_SURVEY === 'true',
        }),
      },
      {
        provide: APPLICATION_INSIGHTS_KEY,
        useFactory: () => environment.application_insights_key,
      },
    ]),
    DsPrintModule,
    DsSpotlightModule,
    DsTextIndicatorModule,
    SharedLibFeaturesFreshdeskWidgetModule.forRoot({
      widgetId: 15000000344,
    }),
    SlRouterContainerComponent,
    SlProjectModule,
    ScSharedStoreModule,
    SlScQuestionnaireDataAccessModule,
    SlSharedScGeneratedDataAccessModule,
    SlScConfigurationDataAccessModule,
  ],
  declarations: [AppComponent],
  providers: [
    // Feature flags
    {
      provide: FEATURE_FLAG_PPI,
      useFactory: () => environment.FEATUREFLAGS.PPI,
    },
    {
      provide: FEATURE_FLAG_EARLY_ACCESS,
      useFactory: () => environment.FEATUREFLAGS.EARLY_ACCESS,
    },
    {
      provide: FEATURE_FLAG_3D_VIEW,
      useFactory: () => environment.FEATUREFLAGS.VIEW_3D,
    },
    {
      provide: FEATURE_FLAG_SERVICE_CONTRACTS,
      useFactory: () => environment.FEATUREFLAGS.SERVICE_CONTRACTS,
    },
    {
      provide: FEATURE_FLAG_BENEFITS,
      useFactory: () => environment.FEATUREFLAGS.BENEFITS,
    },
    {
      provide: FEATURE_FLAG_BULK_ORDERS,
      useFactory: () => environment.FEATUREFLAGS.BULK_ORDERS,
    },
    {
      provide: FEATURE_FLAG_MCC,
      useFactory: () => environment.FEATUREFLAGS.MCC,
    },
    {
      provide: FEATURE_FLAG_WHOLESALE_CHECKBOX,
      useFactory: () => environment.FEATUREFLAGS.WHOLESALE_CHECKBOX,
    },
    {
      provide: FEATURE_FLAG_PALSHOW,
      useFactory: () => environment.FEATUREFLAGS.PALSHOW,
    },
    {
      provide: FEATURE_FLAG_PROJECT_SETTINGS,
      useFactory: () => environment.FEATUREFLAGS.PROJECT_SETTINGS,
    },
    {
      provide: FEATURE_FLAG_SOLUTIONS_INTEGRATION,
      useFactory: () => environment.FEATUREFLAGS.SOLUTIONS_INTEGRATION,
    },
    {
      provide: FEATURE_FLAG_REPORTING,
      useFactory: () => environment.FEATUREFLAGS.REPORTING,
    },
    {
      provide: FEATURE_FLAG_BEST_INVEST,
      useFactory: () => environment.FEATUREFLAGS.BEST_INVEST,
    },
    {
      provide: FEATURE_FLAG_DIGITAL_OFFER,
      useFactory: () => environment.FEATUREFLAGS.DIGITAL_OFFER,
    },
    // MICRO APP RELATED
    {
      provide: PALDESK_BASE_BATH_TOKEN,
      useFactory: () => environment.LINKS.PALDESK_BASEPATH,
    },
    {
      provide: PALFINGER_API_MANAGEMENT_SUBS_KEY,
      useFactory: () => environment.palfinger_api_management_subs_key,
    },
    {
      provide: SCC_DOMAIN,
      useFactory: () => environment.scc_domain,
    },
    {
      provide: PALSHOW_DOMAIN,
      useFactory: () => environment.palshow_domain,
    },
    {
      provide: CART_MAX_VALID_UNTIL_DAYS,
      useFactory: () => environment.cart_max_valid_until_days,
    },
    // HTTP INTERCEPTORS
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    {
      provide: HttpInterceptorTokens.identityServerAuthConf,
      useFactory: () => environment.identityServerAuthConf,
    },
    // AUTH
    {
      provide: AuthTokens.oidcClientId,
      useFactory: () => environment.oidc_clientid,
    },
    {
      provide: AuthTokens.oidcScope,
      useFactory: () => environment.oidc_scope,
    },
    {
      provide: AuthTokens.securityTokenService,
      useFactory: () => environment.LINKS.SECURITY_TOKEN_SERVICE,
    },
    {
      provide: AuthTokens.postLogoutRedirectUri,
      useFactory: () => environment.LINKS.POST_LOGOUT_REDIRECT_URI,
    },
    // STATE
    {
      provide: RouterStateSerializer,
      useClass: CustomRouterStateSerializer,
    },
    // HEADER
    {
      provide: BPM_BASE_PATH,
      useFactory: () => environment.palfinger_app_gateway + '/bpm',
    },
    // SALES LIB BASE PATHS
    {
      provide: CONFIGURATION_BASE_PATH,
      useFactory: configuratorBasePathFactory,
    },
    {
      provide: CUSTOM_ARTICLE_BASE_PATH,
      useFactory: configuratorBasePathFactory,
    },
    {
      provide: PRODUCT_BASE_PATH,
      useFactory: configuratorBasePathFactory,
    },
    {
      provide: PROJECT_BASE_PATH,
      useFactory: configuratorBasePathFactory,
    },
    {
      provide: SETTING_BASE_PATH,
      useFactory: configuratorBasePathFactory,
    },
    {
      provide: UPSELLING_BASE_PATH,
      useFactory: configuratorBasePathFactory,
    },
    {
      provide: ORDERS_BASE_PATH,
      useFactory: () => environment.palfinger_app_gateway + '/sales-order',
    },
    {
      provide: SC_SHARED_BASE_PATH,
      useFactory: scBasePathFactory,
    },
    {
      provide: QUESTIONNAIRE_BASE_PATH,
      useFactory: scBasePathFactory,
    },
    {
      provide: INQUIRY_BASE_PATH,
      useFactory: scBasePathFactory,
    },
    {
      provide: SC_CONFIGURATION_BASE_PATH,
      useFactory: scBasePathFactory,
    },
    {
      provide: SC_SUMMARY_BASE_PATH,
      useFactory: scBasePathFactory,
    },
    // HELP PAGES
    {
      provide: HELP_PAGES_BASE_PATH,
      useFactory: () => environment.wiki_base_path,
    },
    {
      provide: HELP_PAGES_CONFIG,
      useFactory: () =>
        ({
          pagePath: '/General Information/Help Pages',
          projectName: 'Palfinger.ProductConfigurator',
          wikiName: 'Palfinger.ProductConfigurator.wiki',
          appTitle: 'CPQ',
        }) as HelpPagesConfig,
    },
    // SPOTLIGHTS
    {
      provide: DS_DISABLE_SPOTLIGHTS,
      useFactory: () => environment.disable_spotlights,
    },
    // HEALTHCHECK STATUS BANNER
    {
      provide: HEALTH_CHECK_OPTIONS_TOKEN,
      useFactory: (): HealthCheckBannerOptions => ({
        productsToCheck: ['Palfinger ProductConfigurator'],
        statusPaldeskApiUri:
          environment.palfinger_app_gateway + '/status/api/v1/health',
        statusPaldeskPageUri: environment.LINKS.STATUS_PALDESK_PAGE,
      }),
    },
    {
      provide: USER_SERVICE_BASE_PATH,
      useFactory: () => environment.LINKS.SECURITY_TOKEN_SERVICE,
    },
    // TODO: REMOVE WHEN CHANGE TO NEW ANALYTICS LIB IS COMPLETE
    GaService,
    {
      provide: GoogleAnalyticsTrackingId,
      useFactory: () => environment.google_analytics_id,
    },
    // ANIMATIONS
    provideLottieOptions({
      player: playerFactory,
    }),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
